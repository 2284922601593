import React, { useState } from "react";
import { TPrivateUser } from "../../context/user/privateUser.type";
import { EntityEditorWrapper } from "./EntityEditor.style";
import EntityEditorInput from "./components/EntityEditorInput";

type PrivateUserEditorProps = {
  privateUser: TPrivateUser;
  onCloseButtonClick: () => void;
};

const PrivateUserEditor: React.FC<PrivateUserEditorProps> = ({
  privateUser,
  onCloseButtonClick,
}) => {
  const [name, setName] = useState<string>(privateUser.profile.username);
  const [email, setEmail] = useState<string>(privateUser.profile.email);

  return privateUser ? (
    <EntityEditorWrapper>
      <EntityEditorInput
        label="名前"
        value={name}
        onChange={setName}
        disabled={true}
      />
      <EntityEditorInput
        label="メールアドレス"
        value={email}
        onChange={setEmail}
        disabled={true}
      />
    </EntityEditorWrapper>
  ) : (
    <></>
  );
};

export default PrivateUserEditor;
