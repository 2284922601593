import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  FLOWERS,
  ORDERS,
  COMPANY_USERS,
  EVENTS,
  PRIVATE_USERS,
  BANNERS,
  FLOWER_EDITOR,
  EVENT_EDITOR,
  PLANS,
  PLAN_EDITOR,
} from "./settings/constants";
import { theme } from "./theme/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import AuthProvider, { useAuth } from "./context/auth.context";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import FlowerProvider from "./context/flower/flower.context";
import EventProvider from "./context/event/event.context";
import CompanyUserProvider from "./context/user/companyUser.context";
import PrivateUserProvider from "./context/user/privateUser.context";
import OrderProvider from "./context/order/order.context";
import ModalProvider from "./context/modal/modal.context";
import { LoadingProvider } from "./context/loading/loading.context";
import BannerProvider from "./context/banner/banner.context";
import OptionProvider from "./context/option/option.context";
import EventPlans from "./containers/EventPlans";
import EventPlanProvider from "./context/eventPlan/eventPlan.context";
const FlowerEditor = lazy(() => import("./containers/FlowerEditor"));
const Flowers = lazy(() => import("./containers/Flowers/Flowers"));
const Events = lazy(() => import("./containers/Events/Events"));
const EventEditor = lazy(() => import("./containers/EventEditor"));
const EventPlanEditor = lazy(() => import("./containers/EventPlanEditor"));
const Banners = lazy(() => import("./containers/Banners/Banners"));
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Orders = lazy(() => import("./containers/Orders/Orders"));
const CompanyUsers = lazy(() =>
  import("./containers/CompanyUsers/CompanyUsers")
);
const PrivateUsers = lazy(() =>
  import("./containers/PrivateUsers/PrivateUsers")
);
const Login = lazy(() => import("./containers/Login/Login"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LoadingProvider>
          <ModalProvider>
            <FlowerProvider>
              <EventProvider>
                <EventPlanProvider>
                  <BannerProvider>
                    <OrderProvider>
                      <CompanyUserProvider>
                        <PrivateUserProvider>
                          <OptionProvider>
                            <Suspense fallback={<InLineLoader />}>
                              <Switch>
                                <PrivateRoute path={FLOWERS} exact>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <Flowers />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={FLOWER_EDITOR}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <FlowerEditor />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={EVENTS} exact>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <Events />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={EVENT_EDITOR}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <EventEditor />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={PLANS} exact>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <EventPlans />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={PLAN_EDITOR}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <EventPlanEditor />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={BANNERS}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <Banners />{" "}
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={ORDERS}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <Orders />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={COMPANY_USERS}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <CompanyUsers />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <PrivateRoute path={PRIVATE_USERS}>
                                  <AdminLayout>
                                    <Suspense fallback={<InLineLoader />}>
                                      <PrivateUsers />
                                    </Suspense>
                                  </AdminLayout>
                                </PrivateRoute>
                                <Route path={LOGIN}>
                                  <Login />
                                </Route>
                                <Route>
                                  <Login />
                                </Route>
                              </Switch>
                            </Suspense>
                          </OptionProvider>
                        </PrivateUserProvider>
                      </CompanyUserProvider>
                    </OrderProvider>
                  </BannerProvider>
                </EventPlanProvider>
              </EventProvider>
            </FlowerProvider>
          </ModalProvider>
        </LoadingProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default Routes;
