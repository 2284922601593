import { auth } from "./firebase";

export const firebaseClient = {
  currentUser: auth.currentUser,

  getIdToken: async () => await auth.currentUser.getIdToken(),

  onAuthStateChanged: (onNext: (isLoggedIn: boolean) => void): void => {
    auth.onAuthStateChanged(async (user) => {
      if (user) onNext(true);
      else onNext(false);
    });
  },

  signInWithEmailAndPassword: async (
    email: string,
    password: string
  ): Promise<void> => {
    await auth.signInWithEmailAndPassword(email, password);
  },

  signOut: async (): Promise<void> => {
    await auth.signOut();
  },

  sendPasswordResetEmail: async (email: string): Promise<void> => {
    auth.sendPasswordResetEmail(email);
  },
};
