import { TEvent, TEventPlan } from "./event.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";
import {
  TCreateEventPlanRequest,
  TCreateEventRequest,
  TUpdateEventPlanRequest,
  TUpdateEventRequest,
} from "../../infrastructure/flostaApi/flostaApiClient.type";
import { useLoadingModal } from "../loading/loading.context";

type ContextProps = {
  events: Array<TEvent>;
  fetchEvents: () => void;
  createEvent: (TCreateEventRequest) => void;
  updateEvent: (updatedEvent: TUpdateEventRequest, eventId: string) => void;
  deleteEvents: (eventIds: Array<string>) => void;
  createEventPlan: (TCreateEventPlanRequest) => void;
  updateEventPlan: (
    updatedPlan: TUpdateEventPlanRequest,
    planId: string
  ) => void;
  deleteEventPlan: (TEventPlan) => void;
  createEventImages: (files: Array<File>, eventId: string) => void;
  deleteEventImage: (eventImageId: string) => void;
  createEventPlanImages: (files: Array<File>, eventId: string) => void;
  deleteEventPlanImage: (eventImageId: string) => void;
};

const EventContext = createContext({} as ContextProps);

const EventProvider = ({ children }) => {
  const [events, setEvents] = useState<Array<TEvent>>([]);
  const { openLoadingModal, closeLoadingModal } = useLoadingModal();
  const fetchEvents = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const events = await apiClient.get.events(idToken);
      setEvents(events);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  const createEvent = async (newEvent: TCreateEventRequest) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.event(newEvent, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateEvent = async (
    updatedEvent: TUpdateEventRequest,
    eventId: string
  ) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.event(updatedEvent, eventId, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteEvents = async (eventIds: Array<string>) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        eventIds.map((eventId) => apiClient.delete.event(eventId, idToken))
      );
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createEventPlan = async (newPlan: TCreateEventPlanRequest) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.plan(newPlan, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateEventPlan = async (
    updatedPlan: TUpdateEventPlanRequest,
    planId: string
  ) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.plan(updatedPlan, planId, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteEventPlan = async (plan: TEventPlan) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.plan(plan.id, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createEventImages = async (files: Array<File>, eventId: string) => {
    openLoadingModal("商品画像を追加しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        files.map((file) => apiClient.post.eventImage(eventId, file, idToken))
      );
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteEventImage = async (eventImageId: string) => {
    openLoadingModal("商品画像を削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.eventImage(eventImageId, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createEventPlanImages = async (
    files: Array<File>,
    eventPlanId: string
  ) => {
    openLoadingModal("商品画像を追加しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        files.map((file) =>
          apiClient.post.eventPlanImage(eventPlanId, file, idToken)
        )
      );
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteEventPlanImage = async (eventImageId: string) => {
    openLoadingModal("商品画像を削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.eventPlanImage(eventImageId, idToken);
      await fetchEvents();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  return (
    <EventContext.Provider
      value={{
        events,
        fetchEvents,
        createEvent,
        updateEvent,
        deleteEvents,
        createEventPlan,
        updateEventPlan,
        deleteEventPlan,
        createEventImages,
        deleteEventImage,
        createEventPlanImages,
        deleteEventPlanImage,
      }}
    >
      <>{children}</>
    </EventContext.Provider>
  );
};

export const useEvent = () => useContext(EventContext);
export default EventProvider;
