import { TEventPlan } from "./eventPlan.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";
import {
  TCreateEventPlanRequest,
  TUpdateEventPlanRequest,
} from "../../infrastructure/flostaApi/flostaApiClient.type";
import { useLoadingModal } from "../loading/loading.context";

type ContextProps = {
  eventPlans: Array<TEventPlan>;
  fetchEventPlans: () => void;
  updateEventPlan: (
    updatedEventPlan: TUpdateEventPlanRequest,
    eventPlanId: string
  ) => void;
  createEventPlanImages: (files: Array<File>, eventPlanId: string) => void;
  deleteEventPlanImage: (eventPlanImageId: string) => void;
};

const EventPlanContext = createContext({} as ContextProps);

const EventPlanProvider = ({ children }) => {
  const [eventPlans, setEventPlans] = useState<Array<TEventPlan>>([]);
  const { openLoadingModal, closeLoadingModal } = useLoadingModal();
  const fetchEventPlans = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const eventPlans = await apiClient.get.plans(idToken);
      setEventPlans(eventPlans);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  const updateEventPlan = async (
    updatedEventPlan: TUpdateEventPlanRequest,
    eventPlanId: string
  ) => {
    openLoadingModal("イベントを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.plan(updatedEventPlan, eventPlanId, idToken);
      await fetchEventPlans();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createEventPlanImages = async (
    files: Array<File>,
    eventPlanPlanId: string
  ) => {
    openLoadingModal("商品画像を追加しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        files.map((file) =>
          apiClient.post.eventPlanImage(eventPlanPlanId, file, idToken)
        )
      );
      await fetchEventPlans();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteEventPlanImage = async (eventPlanImageId: string) => {
    openLoadingModal("商品画像を削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.eventPlanImage(eventPlanImageId, idToken);
      await fetchEventPlans();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  return (
    <EventPlanContext.Provider
      value={{
        eventPlans,
        fetchEventPlans,
        updateEventPlan,
        createEventPlanImages,
        deleteEventPlanImage,
      }}
    >
      <>{children}</>
    </EventPlanContext.Provider>
  );
};

export const useEventPlan = () => useContext(EventPlanContext);
export default EventPlanProvider;
