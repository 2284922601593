import { TFlower } from "./flower.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";
import { TCreateFlowerRequest } from "../../infrastructure/flostaApi/flostaApiClient.type";
import { useLoadingModal } from "../loading/loading.context";

type ContextProps = {
  flowers: Array<TFlower>;
  fetchFlowers: () => void;
  createFlower: (flower: TCreateFlowerRequest) => void;
  updateFlower: (
    flowerId: string,
    flower: Partial<TCreateFlowerRequest>
  ) => void;
  deleteFlowers: (flowerIds: Array<string>) => void;
  createFlowerImages: (files: Array<File>, flowerId: string) => void;
  deleteFlowerImage: (flowerImageId: string) => void;
};

const FlowerContext = createContext({} as ContextProps);

const FlowerProvider = ({ children }) => {
  const [flowers, setFlowers] = useState<Array<TFlower>>([]);
  const { openLoadingModal, closeLoadingModal } = useLoadingModal();
  const fetchFlowers = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const flowers = await apiClient.get.flowers(idToken);
      setFlowers(flowers);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  const createFlower = async (flower: TCreateFlowerRequest) => {
    openLoadingModal("商品を登録しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.flower(flower, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateFlower = async (
    flowerId: string,
    flower: TCreateFlowerRequest
  ) => {
    openLoadingModal("商品を登録しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.flower(flower, flowerId, idToken);
      await fetchFlowers();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteFlowers = async (flowerIds: Array<string>) => {
    openLoadingModal("商品を削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        flowerIds.map((flowerId) => apiClient.delete.flower(idToken, flowerId))
      );
      await fetchFlowers();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createFlowerImages = async (files: Array<File>, flowerId: string) => {
    openLoadingModal("商品画像を追加しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        files.map((file) => apiClient.post.flowerImage(flowerId, file, idToken))
      );
      await fetchFlowers();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteFlowerImage = async (flowerImageId: string) => {
    openLoadingModal("商品画像を削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.flowerImage(flowerImageId, idToken);
      await fetchFlowers();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  return (
    <FlowerContext.Provider
      value={{
        flowers,
        fetchFlowers,
        createFlower,
        updateFlower,
        deleteFlowers,
        createFlowerImages,
        deleteFlowerImage,
      }}
    >
      <>{children}</>
    </FlowerContext.Provider>
  );
};

export const useFlower = () => useContext(FlowerContext);
export default FlowerProvider;
