import React, { useContext } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { apiClient } from "../infrastructure/apiClient";
import { getFirebaseAuthError } from "../infrastructure/firebase/getFirebaseAuthError";

type AuthProps = {
  isAuthenticated: boolean;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
};

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  const { from } = useMemo(
    () => (location.state as any) || { from: { pathname: "/" } },
    [location.state]
  );

  useEffect(() => {
    apiClient.auth.onAuthStateChanged(async (isLoggedIn) => {
      if (isLoggedIn) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        await apiClient.auth.signInWithEmailAndPassword(email, password);
        history.replace(from);
      } catch (error) {
        throw getFirebaseAuthError(error.code);
      }
    },
    [from, history]
  );

  const signOut = useCallback(async () => {
    try {
      await apiClient.auth.signOut();
    } catch (e) {
      // TODO: Error Handling
      console.log(e);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export const useAuth = () => useContext(AuthContext);
