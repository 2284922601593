import { flostaApiClientBase } from "./flostaApiClient.base";
import { TFlower } from "../../context/flower/flower.type";
import { TEvent, TEventPlan } from "../../context/event/event.type";
import {
  TGetBannersResponse,
  TGetCompanyUsersResponse,
  TGetEventOrdersResponse,
  TGetEventPlansResponse,
  TGetEventsResponse,
  TGetFlowersResponse,
  TGetOrdersResponse,
  TGetPrivateUsersResponse,
} from "./flostaApiClient.type";
import { TCompanyUser } from "../../context/user/companyUser.type";
import { TPrivateUser } from "../../context/user/privateUser.type";
import { TEventOrder, TOrder } from "../../context/order/order.type";
import { TBanner } from "../../context/banner/banner.type";

export const flostaApiClientGet = {
  flowers: async (idToken: string): Promise<Array<TFlower>> =>
    (await flostaApiClientBase.get<TGetFlowersResponse>("/flowers", idToken))
      .data,
  events: async (idToken: string): Promise<Array<TEvent>> =>
    (await flostaApiClientBase.get<TGetEventsResponse>("/events", idToken))
      .data,
  plans: async (idToken: string): Promise<Array<TEventPlan>> =>
    (
      await flostaApiClientBase.get<TGetEventPlansResponse>(
        "/event_plans",
        idToken
      )
    ).data,
  banners: async (idToken: string): Promise<Array<TBanner>> =>
    (await flostaApiClientBase.get<TGetBannersResponse>("/banners", idToken))
      .data,
  companyUsers: async (idToken: string): Promise<Array<TCompanyUser>> =>
    (
      await flostaApiClientBase.get<TGetCompanyUsersResponse>(
        "/company_users",
        idToken
      )
    ).data as Array<TCompanyUser>,
  privateUsers: async (idToken: string): Promise<Array<TPrivateUser>> =>
    (
      await flostaApiClientBase.get<TGetPrivateUsersResponse>(
        "/private_users",
        idToken
      )
    ).data as Array<TPrivateUser>,
  orders: async (idToken: string): Promise<Array<TOrder>> =>
    (await flostaApiClientBase.get<TGetOrdersResponse>("/orders", idToken))
      .data,
  eventOrders: async (idToken: string): Promise<Array<TEventOrder>> =>
    (
      await flostaApiClientBase.get<TGetEventOrdersResponse>(
        "/event_orders",
        idToken
      )
    ).data,
};
