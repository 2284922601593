import React, { useEffect, useState } from "react";
import EventPlanTabel from "../EventForm/components/EventPlanTable";
import { useEventPlan } from "../../context/eventPlan/eventPlan.context";

const EventPlans: React.FC = () => {
  const { fetchEventPlans, eventPlans } = useEventPlan();
  useEffect(() => {
    fetchEventPlans();
  }, []);

  return (
    <>
      <EventPlanTabel eventPlans={eventPlans} />
    </>
  );
};

export default EventPlans;
