import styled from "styled-components";

export const OrderDetailWrapper = styled.div`
  background: white;
  width: 800px;
  height: 1000px;
  padding: 50px;
  overflow-y: scroll;
`;

export const InfoWrapper = styled.div`
  border: 1px solid;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
`;
