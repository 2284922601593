import { TCompanyUser, TCompanyUserState } from "./companyUser.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";

type ContextProps = {
  companyUsers: Array<TCompanyUser>;
  fetchCompanyUsers: () => void;
  updateCompanyUserState: (
    companyUserId: string,
    state: TCompanyUserState
  ) => void;
};

const CompanyUserContext = createContext({} as ContextProps);

const CompanyUserProvider = ({ children }) => {
  const [companyUsers, setCompanyUsers] = useState<Array<TCompanyUser>>([]);
  const fetchCompanyUsers = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const companyUsers = await apiClient.get.companyUsers(idToken);
      setCompanyUsers(companyUsers);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  const updateCompanyUserState = async (
    companyUserId: string,
    state: TCompanyUserState
  ) => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.companyUser(companyUserId, state, idToken);
      await fetchCompanyUsers();
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  return (
    <CompanyUserContext.Provider
      value={{
        companyUsers,
        fetchCompanyUsers,
        updateCompanyUserState,
      }}
    >
      <>{children}</>
    </CompanyUserContext.Provider>
  );
};

export const useCompanyUser = () => useContext(CompanyUserContext);
export default CompanyUserProvider;
