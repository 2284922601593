// **************** ROUTE CONSTANT START **************************
// General Page Section
export const FLOWERS = "/flowers";
export const FLOWER_EDITOR = "/flowers/:flowerId";
export const EVENTS = "/events";
export const EVENT_EDITOR = "/events/:eventId";
export const PLANS = "/plans";
export const PLAN_EDITOR = "/plans/:planId";
export const BANNERS = "/banners";
export const LOGIN = "/login";
export const ORDERS = "/orders";
export const COMPANY_USERS = "/company_users";
export const PRIVATE_USERS = "/private_users";
// **************** ROUTE CONSTANT END **************************
