import {
  TCreateFlowerRequest,
  TUpdateBannerRequest,
  TUpdateChoice,
  TUpdateEventPlanRequest,
  TUpdateEventRequest,
  TUpdateSelectableOption,
  TUpdateTextOption,
} from "./flostaApiClient.type";
import { flostaApiClientBase } from "./flostaApiClient.base";
import { TCompanyUserState } from "../../context/user/companyUser.type";

export const flostaApiClientPatch = {
  flower: async (
    difference: TCreateFlowerRequest,
    flowerId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(difference);
    await flostaApiClientBase.patch<void>(
      `/flowers/${flowerId}`,
      body,
      idToken
    );
  },
  companyUser: async (
    companyUserId: string,
    state: TCompanyUserState,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(state);
    await flostaApiClientBase.patch<void>(
      `/company_users/${companyUserId}`,
      body,
      idToken
    );
  },
  selectableOption: async (
    selectableOption: TUpdateSelectableOption,
    optionId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(selectableOption);
    await flostaApiClientBase.patch(
      `/selectable_options/${optionId}`,
      body,
      idToken
    );
  },
  choice: async (
    choice: TUpdateChoice,
    choiceId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(choice);
    await flostaApiClientBase.patch(`/choices/${choiceId}`, body, idToken);
  },
  textOption: async (
    textOption: TUpdateTextOption,
    textOptionId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(textOption);
    await flostaApiClientBase.patch(
      `/text_options/${textOptionId}`,
      body,
      idToken
    );
  },
  event: async (
    event: TUpdateEventRequest,
    eventId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(event);
    await flostaApiClientBase.patch(`/events/${eventId}`, body, idToken);
  },
  plan: async (
    plan: TUpdateEventPlanRequest,
    planId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(plan);
    await flostaApiClientBase.patch(`/plans/${planId}`, body, idToken);
  },
  banner: async (
    banner: TUpdateBannerRequest,
    bannerId: string,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(banner);
    await flostaApiClientBase.patch(`/banners/${bannerId}`, body, idToken);
  },
};
