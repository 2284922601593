import { TPrivateUser } from "./privateUser.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";

type ContextProps = {
  privateUsers: Array<TPrivateUser>;
  fetchPrivateUsers: () => void;
};

const PrivateUserContext = createContext({} as ContextProps);

const PrivateUserProvider = ({ children }) => {
  const [privateUsers, setPrivateUsers] = useState<Array<TPrivateUser>>([]);
  const fetchPrivateUsers = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const privateUsers = await apiClient.get.privateUsers(idToken);
      setPrivateUsers(privateUsers);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  return (
    <PrivateUserContext.Provider
      value={{
        privateUsers,
        fetchPrivateUsers,
      }}
    >
      <>{children}</>
    </PrivateUserContext.Provider>
  );
};

export const usePrivateUser = () => useContext(PrivateUserContext);
export default PrivateUserProvider;
