import { Modal } from "./modal.type";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Backdrop, Fade, Modal as MuiModal } from "@material-ui/core";
import FlowerOrderDetail from "../../containers/OrderDetail/FlowerOrderDetail";
import CompanyUserEditor from "../../containers/EntityEditor/CompanyUserEditor";
import PrivateUserEditor from "../../containers/EntityEditor/PrivateUserEditor";

type ContextProps = {
  openModal: (modal: Modal, props: any) => void;
  closeModal: () => void;
};

const ModalContext = createContext({} as ContextProps);

const ModalProvider = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<Modal>(null);
  const [modalProps, setModalProps] = useState<any>(null);

  const openModal = useCallback((modal: Modal, props: any) => {
    setModalProps(props);
    setCurrentModal(modal);
  }, []);

  const closeModal = useCallback(() => {
    setCurrentModal(null);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <>{children}</>
      {Object.values(Modal).map((modal) => (
        <GlobalFadeModal open={currentModal === modal} onClose={closeModal}>
          {modal === Modal.PrivateUserDetail ? (
            <PrivateUserEditor {...modalProps} />
          ) : modal === Modal.CompanyUserDetail ? (
            <CompanyUserEditor {...modalProps} />
          ) : (
            modal === Modal.OrderDetail && <FlowerOrderDetail {...modalProps} />
          )}
        </GlobalFadeModal>
      ))}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
export default ModalProvider;

type GlobalFadeModalProps = {
  open: boolean;
  onClose: () => void;
};

const GlobalFadeModal: React.FC<GlobalFadeModalProps> = ({
  open,
  onClose,
  children,
}) => (
  <MuiModal
    open={open}
    onClose={onClose}
    closeAfterTransition={true}
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Fade
      in={open}
      timeout={{
        enter: 200,
        exit: 200,
      }}
    >
      <>{children}</>
    </Fade>
  </MuiModal>
);
