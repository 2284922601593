import { firebaseClient } from "./firebase/firebaseClient";
import { flostaApiClientGet } from "./flostaApi/flostaApiClient.get";
import { flostaApiClientPost } from "./flostaApi/flostaApiClient.post";
import { flostaApiClientPatch } from "./flostaApi/flostaApiClient.patch";
import { flostaApiClientDelete } from "./flostaApi/flostaApiClient.delete";

export const apiClient = {
  auth: firebaseClient,
  get: flostaApiClientGet,
  post: flostaApiClientPost,
  patch: flostaApiClientPatch,
  delete: flostaApiClientDelete,
};
