// Your web app's Firebase configuration
import firebase from "firebase";
import "firebase/auth";
import "firebase/functions";
import config from "../../settings/config";

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    appId: config.firebase.appId,
  });
}

// Authのみ使用
export const auth = firebase.auth();
