import {
  TCreateBannerRequest,
  TCreateChoice,
  TCreateEventPlanRequest,
  TCreateEventRequest,
  TCreateFlowerRequest,
  TCreateSelectableOption,
  TCreateTextOption,
} from "./flostaApiClient.type";
import { flostaApiClientBase } from "./flostaApiClient.base";

export const flostaApiClientPost = {
  flower: async (
    flower: TCreateFlowerRequest,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(flower);
    await flostaApiClientBase.post("/flowers", body, idToken);
  },
  event: async (event: TCreateEventRequest, idToken: string): Promise<void> => {
    const body = JSON.stringify(event);
    await flostaApiClientBase.post("/events", body, idToken);
  },
  plan: async (
    plan: TCreateEventPlanRequest,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(plan);
    await flostaApiClientBase.post("/plans", body, idToken);
  },
  banner: async (
    banner: TCreateBannerRequest,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(banner);
    await flostaApiClientBase.post("/banners", body, idToken);
  },
  selectableOption: async (
    selectableOption: TCreateSelectableOption,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(selectableOption);
    await flostaApiClientBase.post("/selectable_options", body, idToken);
  },
  choice: async (choice: TCreateChoice, idToken: string): Promise<void> => {
    const body = JSON.stringify(choice);
    await flostaApiClientBase.post("/choices", body, idToken);
  },
  textOption: async (
    textOption: TCreateTextOption,
    idToken: string
  ): Promise<void> => {
    const body = JSON.stringify(textOption);
    await flostaApiClientBase.post("/text_options", body, idToken);
  },
  flowerImage: async (
    flowerId: string,
    flowerImage: File,
    idToken: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("flowerId", flowerId);
    formData.append("image", flowerImage);
    await flostaApiClientBase.post("/flower_images", formData, idToken);
  },
  bannerImage: async (
    bannerId: string,
    bannerImage: File,
    idToken: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("bannerId", bannerId);
    formData.append("image", bannerImage);
    await flostaApiClientBase.post("/banner_images", formData, idToken);
  },
  eventImage: async (
    eventId: string,
    eventImage: File,
    idToken: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("eventId", eventId);
    formData.append("image", eventImage);
    await flostaApiClientBase.post("/event_images", formData, idToken);
  },
  eventPlanImage: async (
    eventPlanId: string,
    eventPlanImage: File,
    idToken: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("eventPlanId", eventPlanId);
    formData.append("image", eventPlanImage);
    await flostaApiClientBase.post("/event_plan_images", formData, idToken);
  },
};
