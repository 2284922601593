import styled from "styled-components";

export const EntityEditorWrapper = styled.div`
  background: white;
  width: 1000px;
  height: auto;
  padding: 50px;
  max-height: 90%;
  max-width: 90%;
`;
