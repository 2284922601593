import React, { createContext, useContext } from "react";
import {
  TCreateChoice,
  TCreateSelectableOption,
  TCreateTextOption,
  TUpdateChoice,
  TUpdateSelectableOption,
  TUpdateTextOption,
} from "../../infrastructure/flostaApi/flostaApiClient.type";
import { useLoadingModal } from "../loading/loading.context";
import { apiClient } from "../../infrastructure/apiClient";
import { TChoice, TSelectableOption, TTextOption } from "../flower/flower.type";

type ContextProps = {
  createSelectableOption: (TCreateSelectableOption) => void;
  updateSelectableOption: (
    option: TUpdateSelectableOption,
    optionId: string
  ) => void;
  createChoice: (TCreateChoice) => void;
  updateChoice: (choice: TUpdateChoice, choiceId: string) => void;
  deleteSelectableOption: (option: TSelectableOption) => void;
  deleteChoice: (choice: TChoice) => void;
  createTextOption: (TCreateTextOption) => void;
  updateTextOption: (
    textOption: TUpdateTextOption,
    textOptionId: string
  ) => void;
  deleteTextOption: (TTextOption) => void;
};

const OptionContext = createContext({} as ContextProps);

const OptionProvider = ({ children }) => {
  const { openLoadingModal, closeLoadingModal } = useLoadingModal();

  const createSelectableOption = async (option: TCreateSelectableOption) => {
    openLoadingModal("オプションを登録しています");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.selectableOption(option, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateSelectableOption = async (
    option: TUpdateSelectableOption,
    optionId: string
  ) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.selectableOption(option, optionId, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteSelectableOption = async (option: TSelectableOption) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.selectableOption(idToken, option.id);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createChoice = async (choice: TCreateChoice) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.choice(choice, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateChoice = async (choice: TUpdateChoice, choiceId: string) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.choice(choice, choiceId, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteChoice = async (choice: TChoice) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.choice(idToken, choice.id);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createTextOption = async (textOption: TCreateTextOption) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.textOption(textOption, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateTextOption = async (
    textOption: TUpdateTextOption,
    textOptionId: string
  ) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.textOption(textOption, textOptionId, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteTextOption = async (textOption: TTextOption) => {
    openLoadingModal("オプションを更新しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.textOption(idToken, textOption.id);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  return (
    <OptionContext.Provider
      value={{
        createSelectableOption,
        updateSelectableOption,
        createChoice,
        updateChoice,
        deleteChoice,
        deleteSelectableOption,
        createTextOption,
        updateTextOption,
        deleteTextOption,
      }}
    >
      <>{children}</>
    </OptionContext.Provider>
  );
};

export const useOption = () => useContext(OptionContext);
export default OptionProvider;
