import { TBanner } from "./banner.type";
import React, { createContext, useContext, useState } from "react";
import { apiClient } from "../../infrastructure/apiClient";
import {
  TCreateBannerRequest,
  TUpdateBannerRequest,
} from "../../infrastructure/flostaApi/flostaApiClient.type";
import { useLoadingModal } from "../loading/loading.context";

type ContextProps = {
  banners: Array<TBanner>;
  fetchBanners: () => void;
  createBanner: (banner: TCreateBannerRequest) => void;
  updateBanner: (
    bannerId: string,
    banner: Partial<TCreateBannerRequest>
  ) => void;
  deleteBanner: (bannerId: string) => void;
  createBannerImages: (files: Array<File>, bannerId: string) => void;
  deleteBannerImage: (bannerImageId: string) => void;
};

const BannerContext = createContext({} as ContextProps);

const BannerProvider = ({ children }) => {
  const [banners, setBanners] = useState<Array<TBanner>>([]);
  const { openLoadingModal, closeLoadingModal } = useLoadingModal();
  const fetchBanners = async () => {
    try {
      const idToken = await apiClient.auth.getIdToken();
      const banners = await apiClient.get.banners(idToken);
      setBanners(banners);
    } catch (e) {
      //TODO: Error Handling
      console.log(e);
    }
  };

  const createBanner = async (banner: TCreateBannerRequest) => {
    openLoadingModal("バナーを登録しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.post.banner(banner, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const updateBanner = async (
    bannerId: string,
    banner: TUpdateBannerRequest
  ) => {
    openLoadingModal("バナーを登録しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.patch.banner(banner, bannerId, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteBanner = async (bannerId: string) => {
    openLoadingModal("バナーを削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.banner(bannerId, idToken);
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const createBannerImages = async (files: Array<File>, bannerId: string) => {
    openLoadingModal("商品画像を追加しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await Promise.all(
        files.map((file) => apiClient.post.bannerImage(bannerId, file, idToken))
      );
      await fetchBanners();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  const deleteBannerImage = async (bannerImageId: string) => {
    openLoadingModal("商品画像を削除しています...");
    try {
      const idToken = await apiClient.auth.getIdToken();
      await apiClient.delete.bannerImage(bannerImageId, idToken);
      await fetchBanners();
    } catch (e) {
      console.log(e);
    } finally {
      closeLoadingModal();
    }
  };

  return (
    <BannerContext.Provider
      value={{
        banners,
        fetchBanners,
        createBanner,
        updateBanner,
        deleteBanner,
        createBannerImages,
        deleteBannerImage,
      }}
    >
      <>{children}</>
    </BannerContext.Provider>
  );
};

export const useBanner = () => useContext(BannerContext);
export default BannerProvider;
