import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";

type FlowerEditorInputProps = {
  label: string;
  value: string | boolean | number;
  onChange: (value: any) => void;
  multiline?: boolean;
  disabled?: boolean;
  type?: string;
};

const EntityEditorInput: React.FC<FlowerEditorInputProps> = ({
  label,
  value,
  onChange,
  multiline = false,
  disabled = false,
  type = "text",
}) => (
  <Grid container spacing={2} style={{ marginTop: "15px" }}>
    <Grid item xs={2} style={{ textAlign: "right", marginTop: "10px" }}>
      <Typography style={{ lineHeight: "40px" }}>{label}</Typography>
    </Grid>
    <Grid item xs={10}>
      <TextField
        variant="outlined"
        multiline={multiline}
        rows={multiline ? 4 : 1}
        disabled={disabled}
        value={value}
        size="small"
        onChange={(e) => onChange(e.target.value)}
        style={{ width: "100%", marginTop: "10px" }}
        type={type}
      />
    </Grid>
  </Grid>
);

export default EntityEditorInput;
